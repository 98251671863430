import { IS_DEV } from "@constants";

type LocalStorageReturnType = {
  error?: Error;
};

export function writeToLocalStorage<T>(
  key: string,
  value: T
): LocalStorageReturnType {
  try {
    localStorage.setItem(key, JSON.stringify(value));

    return {};
  } catch (maybeError) {
    if (IS_DEV) {
      console.error(maybeError);
    }

    const error =
      maybeError instanceof Error
        ? maybeError
        : new Error("Unable to write localStorage data.");

    return { error };
  }
}

export function readFromLocalStorage<T>(
  key: string,
  defaultValue: T
): {
  value: T;
  error?: Error;
} {
  try {
    const raw = localStorage.getItem(key);

    if (raw) {
      const parsed = JSON.parse(raw);
      return {
        value: parsed,
      };
    }

    return {
      value: defaultValue,
    };
  } catch (maybeError) {
    if (IS_DEV) {
      console.error(maybeError);
    }

    const error =
      maybeError instanceof Error
        ? maybeError
        : new Error("Unable to read localStorage data.");

    return {
      value: defaultValue,
      error,
    };
  }
}

export const removeFromLocalStorage = (key: string): LocalStorageReturnType => {
  try {
    localStorage.removeItem(key);

    return {};
  } catch (error: unknown) {
    console.error(error);

    return {
      error: error as Error,
    };
  }
};
