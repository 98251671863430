import { InboundFlightDTO, OutboundFlightDTO } from "@models/flightInfo";
import { generateMockData } from "./mockUtils";

const getInboundFlightApiMock = generateMockData<InboundFlightDTO>({
  aircraft_type: "737",
  arrival_airport: "JFK",
  company_iata: "AA",
  departure_airport: "LAX",
  flight_number: "AA123",
  airline: "American Airlines",
  actual_in_block_time: 1605555921,
  scheduled_in_block_time: 1605555900,
  actual_landing_time: 1605555921,
  scheduled_landing_time: 1605555900,
  scheduled_date_time: 1605555900,
  estimated_in_block_time: 1605555915,
});

const getOutboundFlightApiMock = (): OutboundFlightDTO => ({
  aircraft_type: "737",
  arrival_airport: "MIA",
  company_iata: "DL",
  departure_airport: "JFK",
  flight_number: "DL456",
  airline: "Delta Air Lines",
  actual_off_block_time: 1605555921,
  off_block_time: 1605555900,
  scheduled_off_block_time: 1605555900,
  estimated_off_block_time: 1605555915,
  target_off_block_time: 1605555900,
  actual_take_off_time: 1605555921,
  scheduled_take_off_time: 1605555900,
  scheduled_date_time: 1605555900,
  latest_time_of_departure: 1605555930,
  is_star_flight: false,
});

export { getInboundFlightApiMock, getOutboundFlightApiMock };
