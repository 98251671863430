import { AxiosRequestConfig } from "axios";
import { ApiPostMiddlewareFunction, ApiPreMiddlewareFunction } from "../types";
import * as baMiddlewares from "./ba";
import * as jfkMiddlewares from "./jfk";
import * as seaMiddlewares from "./sea";
import * as commonMiddlewares from "./common";

export const preMiddlewares: Record<
  string,
  ApiPreMiddlewareFunction<AxiosRequestConfig, AxiosRequestConfig>
> = {
  seaExtendTimelineRequestRange: seaMiddlewares.extendTimelineRequestRange,
};

export const postMiddlewares: Record<string, ApiPostMiddlewareFunction> = {
  baPathStands: baMiddlewares.patchStands,

  jfkPatchStands: jfkMiddlewares.patchStands,

  seaHandrailsMiddleware: seaMiddlewares.handrailsMiddleware,
  seaWingWalkersMiddleware: seaMiddlewares.wingWalkersMiddleware,
  seaFixB7bTurn: seaMiddlewares.fixB7bTurn,

  leaveFirstAndLastWalkaround: commonMiddlewares.leaveFirstAndLastWalkaround,
  lifecycleMiddleware: commonMiddlewares.lifecycleMiddleware,
};
