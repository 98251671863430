import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import cloneDeep from "lodash/cloneDeep";
import { getDiff } from "recursive-diff";

import { DIProps } from "@di";
import { isFunction } from "@services/utils";
import { IS_DEV } from "@constants";
import { ApiPreMiddlewareFunction } from "../types";
import { postMiddlewares, preMiddlewares } from "../middlewares";
import { ApiMiddlewareConfig } from "@frontend/configuration";

export class ApiClient {
  serverUnavailableCount = 0;
  private readonly _instance: AxiosInstance;

  constructor(private di: DIProps) {
    if (IS_DEV || this.di.configOverrides.isDebug) {
      console.debug("[API] ApiClient init.");
    }

    makeObservable(this, {
      serverUnavailableCount: observable,
      serverUnavailable: computed,
    });

    this._instance = axios.create({
      transformRequest: [
        function (data: unknown, headers) {
          const { user, locale } = di;
          const jwt = "JWT " + user.id_token;
          if (headers) {
            headers["Authorization"] = jwt;

            if (locale) {
              headers["Accept-Language"] = locale;
            }

            if (data) {
              headers["Content-Type"] = "application/json";
              data = JSON.stringify(data);
            }
          }
          return data;
        },
      ],
    });

    this._instance.interceptors.request.use((config) => {
      return config;
    }, this.onError);

    this._instance.interceptors.response.use(
      action((response) => {
        if (response.headers["content-type"] === "text/html") {
          const error = new Error(
            "[API#performRequest] Wrong resp format (html instead of json)."
          );
          this.onError(error);
          throw error;
        }

        this.serverUnavailableCount = 0;

        return response;
      }),
      this.onError
    );

    this.performRequest = this.performRequest.bind(this);
  }

  get serverUnavailable() {
    return this.serverUnavailableCount > 3;
  }

  onError = (error: AxiosError | Error) => {
    if (axios.isAxiosError(error) && String(error.code) === "418") {
      this.serverUnavailableCount = 0;
      // With no stands only exception will be displayed, unless header could be displayed
      // TODO
      // rootStore.standsStore.resetStands();
      return Promise.reject(error);
    }

    runInAction(() => {
      this.serverUnavailableCount++;
    });

    reportError(error);

    return Promise.reject(error);
  };

  async performRequest<
    T,
    R = T,
    C extends AxiosRequestConfig = AxiosRequestConfig,
  >(config: C, parseResponse: (responseData: R) => T): Promise<T>;

  async performRequest<R, C extends AxiosRequestConfig = AxiosRequestConfig>(
    config: C
  ): Promise<R>;

  async performRequest<
    T,
    R = T,
    C extends AxiosRequestConfig = AxiosRequestConfig,
  >(config: C, parseResponse?: (responseData: R) => T): Promise<R | T> {
    const { url } = config;

    if (!url) {
      return Promise.reject("[API#performRequest] Missing url.");
    }

    let apiMiddlewares: ApiMiddlewareConfig[] = [];
    let requestTimeout = 30000;

    try {
      const appConfig = this.di.config;
      apiMiddlewares = appConfig.apiMiddlewares;
      requestTimeout = appConfig.requestTimeout;
    } catch (e: unknown) {
      console.warn(e);
    }

    config.timeout = requestTimeout;

    const middleWares = apiMiddlewares.filter((m) =>
      new RegExp(m.urlRegex).test(url)
    );

    middleWares.forEach((middleware) => {
      if (!middleware.preMiddleware) {
        return;
      }

      const preMiddleware = preMiddlewares[middleware.preMiddleware] as
        | ApiPreMiddlewareFunction<C, C>
        | undefined;

      if (!isFunction(preMiddleware)) {
        console.error(
          new Error(
            `[API#performRequest] Missing preMiddleware ${middleware.preMiddleware} middleware.`
          )
        );

        return;
      }

      if (isFunction(middleware)) {
        // FIXME: improve add type inference (generics) for middleware types
        config = preMiddleware(config);
      }
    });

    const { data: rawData } = (await this._instance(config)) as AxiosResponse<
      R,
      C
    >;

    let data = isFunction(parseResponse) ? parseResponse(rawData) : rawData;

    middleWares.forEach((middleware) => {
      if (!middleware.postMiddleware) {
        return;
      }

      const postMiddleware = postMiddlewares[middleware.postMiddleware];

      if (!isFunction(postMiddleware)) {
        console.error(
          new Error(
            `[API#performRequest] Missing postMiddleware ${middleware.postMiddleware} middleware.`
          )
        );

        return;
      }

      try {
        const { isDebug } = this.di.configOverrides;

        const beforeMiddleware = isDebug ? cloneDeep(data) : null;

        // FIXME: improve add type inference (generics) for middleware types
        data = postMiddleware(data, this.di.user);

        const afterMiddleware = isDebug ? cloneDeep(data) : null;

        if (isDebug) {
          const diff = getDiff(beforeMiddleware, afterMiddleware, true);

          if (diff.length) {
            console.debug(
              `[API#performRequest] Post middleware "${middleware.postMiddleware}" diff:`,
              diff
            );
          }
        }
      } catch (error) {
        console.error(error);

        throw error;
      }
    });

    return data;
  }
}
