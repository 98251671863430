import { DetectionShortDTO, FullDetectionDTO } from "@models/detection";
import { generateMockData } from "./mockUtils";

const getDetectionShortDTOMock = generateMockData<DetectionShortDTO>({
  id: "63867c8232e6ca480a757591",
  confidence: 1,
  start_ts: 162425000,
  end_ts: 162425600,
  start_type: "start_type",
  end_type: "end_type",
  op_name: "op_name",
});

const getDetectionFullDTOMock = generateMockData<FullDetectionDTO>({
  ...getDetectionShortDTOMock(),
  start_confidence: 1,
  end_confidence: 1,
  start_detection_gap: 1,
  end_detection_gap: 1,
  start_state: "start_state",
  end_state: "end_state",
  bbox_ranges: {},
});

export { getDetectionShortDTOMock, getDetectionFullDTOMock };
