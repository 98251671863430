import { MODAL_SAFETY_ID_QUERY_KEY, SAFETY_PATH } from "@constants";

export const isSafetyPath = (pathname: string) =>
  pathname.includes(SAFETY_PATH);

export const getTurnaroundTabFromPath = (
  pathname: string
): "operations" | "safety" => {
  if (isSafetyPath(pathname)) {
    return SAFETY_PATH;
  }

  return "operations";
};

/**
 * Returns link to safety if current url contains safety
 */
export const getSafetyPath = (base: string): string => {
  const href = window.location.href;

  if (isSafetyPath(base)) {
    return base;
  }

  return isSafetyPath(href) ? `${base}/safety` : base;
};

export const generateModalSafetyPath = (
  path: string,
  safetyEventId?: string
) => {
  if (safetyEventId) {
    return `${path}?${MODAL_SAFETY_ID_QUERY_KEY}=${safetyEventId}`;
  }

  return path;
};
