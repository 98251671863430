type FlightData = { eobt: number | null; sobt: number };
type FlightDataNullable = {
  eobt: number | null;
  sobt: number | null;
};
type Result = {
  timeToUse: number;
  deltaMilliseconds: number;
  valueMinutes: number;
};

export function getFlightDepartureDelay(flightData: FlightData): Result;
export function getFlightDepartureDelay(
  flightData: FlightDataNullable
): Result | null;
export function getFlightDepartureDelay({
  eobt,
  sobt,
}: FlightData | FlightDataNullable): Result | null {
  const timeToUse = eobt || sobt;

  if (!timeToUse) {
    return null;
  }

  const deltaMilliseconds = timeToUse - Date.now();
  const valueMinutes = Math.ceil(deltaMilliseconds / (60 * 1000)) * -1;

  return {
    timeToUse,
    deltaMilliseconds,
    valueMinutes,
  };
}
