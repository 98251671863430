import { AxiosRequestConfig } from "axios";
import { ApiSpecPaths, ApiSpecModels } from "@models/apiSpec";
import { IncidentDTO } from "@models/incidentConfig";
import { FullDetectionDTO } from "@models/detection";
import { StandDTO } from "@models/stand";
import { TurnaroundDetailDTO } from "@models/turnaround";
import { getDetectionFullDTOMock } from "@services/mocks/detectionMocks";
import { generateMockData } from "@services/mocks/mockUtils";
import { getTurnaroundDetailDTOMock } from "@services/mocks/turnMocks";

import { AlertsParams } from "../types";

type ApiConfigReturnType<T, D = any> = {
  config: AxiosRequestConfig<D> & { url: string };
  getMockResponse: ReturnType<typeof generateMockData<T>>;
};

export type RequestParams<T extends Record<string, unknown> = any> = T;

// /api/stands/{stand_id}/turnarounds/{turnaround_id}
export const getFullTurnaroundApiConfig = (
  standId: string,
  turnId: string
): ApiConfigReturnType<{
  current_ts: number;
  last_image_ts: number;
  last_prediction_ts: number;
  online: Record<string, boolean>;
  turnaround: ReturnType<typeof getTurnaroundDetailDTOMock>;
}> => {
  return {
    config: {
      url: `/api/stands/${standId}/turnarounds/${turnId}`,
      method: "GET",
    },
    getMockResponse: generateMockData({
      online: {},
      current_ts: 162425600,
      last_image_ts: 162425600,
      last_prediction_ts: 162425600,
      turnaround: getTurnaroundDetailDTOMock(),
    }),
  };
};

// /api/stands/turnarounds/
export const getActiveTurnaroundsApiConfig = (
  lastRequestTs?: number
): ApiConfigReturnType<TurnaroundDetailDTO[]> => {
  const params: RequestParams = {};
  if (lastRequestTs) {
    params.since = lastRequestTs / 1000;
  }

  const getMockResponse = generateMockData<TurnaroundDetailDTO[]>([]);

  return {
    config: {
      url: "/api/stands/turnarounds/",
      method: "GET",
      params,
    },
    getMockResponse,
  };
};

// /api/stands/{stand_id}/turnarounds/
export const getStandTurnaroundsApiConfig = (
  standId: string,
  options: {
    turnaroundsRequestLimit?: number;
    params?: RequestParams<{ limit?: number; before?: number; after?: number }>;
  }
): ApiConfigReturnType<TurnaroundDetailDTO[]> => {
  const { turnaroundsRequestLimit, params } = options;

  if (params) {
    if (!params.limit) {
      params.limit = turnaroundsRequestLimit;
    }
    if (params.before) {
      params.before = Math.floor(params.before / 1000);
    }
    if (params.after) {
      params.after = Math.floor(params.after / 1000);
    }
  }
  const getMockResponse = generateMockData<TurnaroundDetailDTO[]>([]);

  return {
    config: {
      url: `/api/stands/${standId}/turnarounds/`,
      method: "GET",
      params,
    },
    getMockResponse,
  };
};

// /api/stands/{stand_id}/timeline
export const getTimelineApiConfig = (
  standId: string,
  startTs: number,
  endTs?: number | null
): ApiConfigReturnType<{
  current_ts: number;
  last_image_ts: number;
  last_prediction_ts: number;
  detections: any; // TODO: get rid of any
  camera_outages: any; // TODO: get rid of any
  turnarounds: TurnaroundDetailDTO[];
}> => {
  startTs = startTs / 1000;

  const params: RequestParams = {
    start_ts: startTs,
  };

  if (endTs) {
    params.end_ts = endTs / 1000;
  }

  return {
    config: {
      url: `/api/stands/${standId}/timeline`,
      params,
    },
    getMockResponse: generateMockData({
      current_ts: 162425600,
      last_image_ts: 162425600,
      last_prediction_ts: 162425600,
      turnarounds: [] as TurnaroundDetailDTO[],
      detections: [],
      camera_outages: [],
    }),
  };
};

// /api/stands/{stand_id}/turnarounds/{turnaround_id}/timeline
export const getTurnaroundTimelineApiConfig = (
  standId: string,
  turnId: string
): ApiConfigReturnType<{
  current_ts: number;
  last_image_ts: number;
  last_prediction_ts: number;
  detections: any; // TODO: get rid of any
  camera_outages: any; // TODO: get rid of any
  turnaround: TurnaroundDetailDTO;
}> => {
  return {
    config: {
      url: `/api/stands/${standId}/turnarounds/${turnId}/timeline`,
    },
    getMockResponse: generateMockData({
      current_ts: 162425600,
      last_image_ts: 162425600,
      last_prediction_ts: 162425600,
      turnaround: getTurnaroundDetailDTOMock(),
      detections: [],
      camera_outages: [],
    }),
  };
};

// /api/stands/
export const getStandsApiConfig = (): ApiConfigReturnType<StandDTO[]> => {
  return {
    config: {
      url: `/api/stands/`,
      method: "GET",
    },
    getMockResponse: generateMockData<StandDTO[]>([]),
  };
};

// /api/stands/search
export const searchTurnaroundsApiConfig = (options: {
  stand: string | null | undefined;
  query: string;
  before?: number;
  limit?: number;
}): ApiConfigReturnType<TurnaroundDetailDTO[]> => {
  const { stand, query, before, limit } = options;
  const params: RequestParams = {
    limit,
  };

  if (before) {
    params.before = Math.round(before / 1000);
  }
  if (stand) {
    params.stand_id = stand;
  }
  if (query) {
    params.q = query;
  }

  return {
    config: {
      url: "/api/stands/search",
      method: "GET",
      params,
    },
    getMockResponse: generateMockData<TurnaroundDetailDTO[]>([]),
  };
};

// /api/stands/detections/{detection_id}
export const getFetchDetectionApiConfig = (
  id: string
): ApiConfigReturnType<FullDetectionDTO> => {
  return {
    config: {
      url: `/api/stands/detections/${id}`,
    },
    getMockResponse: generateMockData<FullDetectionDTO>(
      getDetectionFullDTOMock()
    ),
  };
};

// /api/stands/incidents
export const getAlertsApiConfig = (
  params: AlertsParams
): ApiConfigReturnType<IncidentDTO[]> => {
  return {
    config: {
      url: "/api/stands/incidents",
      params,
    },
    getMockResponse: generateMockData<IncidentDTO[]>([]),
  };
};

// /api/stands/incidents/summary/
export const getIncidentsSummaryApiConfig = (): ApiConfigReturnType<
  ApiSpecModels["IncidentSummary"][]
> => {
  const url: ApiSpecPaths = "/api/stands/incidents/summary/";
  return {
    config: {
      url,
    },
    getMockResponse: generateMockData<ApiSpecModels["IncidentSummary"][]>([]),
  };
};
