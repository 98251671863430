import { ApiPostMiddlewareFunction } from "../types";
import { Stand, Camera } from "@models/stand";

export const patchStands: ApiPostMiddlewareFunction<Stand[]> = (stands) => {
  const camerasOrder = ["-s", "-p", "-b"];
  stands.forEach((s) => {
    const cameras: Camera[] = [];
    camerasOrder.forEach((str) => {
      const index = s.cameras.findIndex((c) => c.id.includes(str));
      const camera = s.cameras.splice(index, 1)[0];
      if (camera) {
        cameras.push(camera);
      }
    });
    cameras.push(...s.cameras);
    s.cameras = cameras;
  });

  return stands;
};
