import cloneDeep from "lodash/cloneDeep";
import { ApiPostMiddlewareFunction } from "../types";
import { Stand } from "@models/stand";

const camChanges = [
  [
    "jfk-a2a",
    [
      ["jfk-a2-p", "a2a-p"],
      ["jfk-a2-s", "a2a-s"],
    ],
  ],
  [
    "jfk-a2b",
    [
      ["jfk-a2-p", "a2b-p"],
      ["jfk-a2-s", "a2b-s"],
    ],
  ],
  ["jfk-a7", [["jfk-a7-p3", "a7-p"]]],
  ["jfk-a7a", [["jfk-a7-p3", "a7-p"]]],
  ["jfk-a7b", [["jfk-a7-p3", "a7-p"]]],
] as const;

export const patchStands: ApiPostMiddlewareFunction<Stand[]> = (
  stands,
  user
) => {
  stands = cloneDeep(stands);

  camChanges.forEach(([id, pairs]) => {
    const stand = stands.find((s) => s.id === id);
    if (!stand) {
      return;
    }
    pairs.forEach(([camId, newCamLabel]) => {
      const cam = stand.cameras.find((c) => c.id === camId);
      if (cam) {
        cam.label = newCamLabel;
      }
    });
  });

  const roles = user.profile.roles || [];
  if (!roles.find((r) => ["assaia", "superuser", "JFKIAT"].includes(r))) {
    stands.forEach((stand) => {
      stand.cameras = stand.cameras.filter(
        (c) => !["jfk-b24-b", "jfk-a2a-b"].includes(c.id)
      );
    });
  }

  return stands;
};
