import { TurnaroundParameterKey } from "@frontend/configuration";
import { ApiSpecModels } from "./apiSpec";

export type TurnaroundParamName =
  | NonNullable<ApiSpecModels["TurnaroundParam"]["parameter_name"]>
  | TurnaroundParameterKey;

export type TurnaroundParams = Record<TurnaroundParamName, number | null> & {
  [PARAMS_NULLED_SYMBOL]?: TurnaroundParamName[];
};
export const PARAMS_NULLED_SYMBOL = Symbol(
  "Some turnaround params was nulled on client side"
);
