import { TurnaroundDetailDTO } from "@models/turnaround";
import {
  getOutboundFlightApiMock,
  getInboundFlightApiMock,
} from "./flightMocks";
import { generateMockData } from "./mockUtils";

const getTurnaroundDetailDTOMock = generateMockData<TurnaroundDetailDTO>({
  id: "63867c8232e6ca480a757591",
  detections: [],
  authorized: true,
  dedicated_airline: "XYZ Airlines",
  end_ts: 162425600,
  original_stand_id: "A1",
  params: {
    aircraft_end_ts: 162425600,
    aircraft_on_stand_end_ts: 162425480,
    aircraft_on_stand_start_ts: 162425120,
    aircraft_start_ts: 162424000,
    aldt: 10800,
    eibt: 162425120,
    eobt: 162425480,
    ltd: 7200,
    pobt: 162425360,
    sibt: 162424000,
    sobt: 162426000,
    prdt: 162426000,
  },
  pobt: 162425360,
  progress: 80,
  pushback_speed_max: 5,
  replays: {
    replay1: {
      end_ts: 162425600,
      speed: 2,
      start_ts: 162425480,
      url: "https://example.com/replay1",
    },
    replay2: {
      end_ts: 162425480,
      speed: 3,
      start_ts: 162425120,
      url: "https://example.com/replay2",
    },
  },
  stand_id: "A2",
  start_ts: 162424000,
  state: "finished",
  inbound_flight: getInboundFlightApiMock(),
  outbound_flight: getOutboundFlightApiMock(),
});

export { getTurnaroundDetailDTOMock };
