import { DEFAULT_LOCALE, DEFAULT_LOCALES } from "@i18n";
import { asClass, asValue } from "awilix";
import { ApiClient } from "@api/ApiClient";
import { Api } from "@api";
import { LocalConfigOverrider } from "@services/localConfigOverrider";
import { di } from ".";

export function registerDependencies() {
  di.register({
    apiClient: asClass(ApiClient, { lifetime: "SINGLETON" }),
    api: asClass(Api, { lifetime: "SINGLETON" }),
    configOverrides: asClass(LocalConfigOverrider, { lifetime: "SINGLETON" }),
    locale: asValue(DEFAULT_LOCALE),
    locales: asValue(DEFAULT_LOCALES),
  });
}
