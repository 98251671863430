import { useEffect, useState } from "react";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  getUserLocale,
  handleIntlError,
  handleIntlWarning,
  loadLocaleData,
} from "@i18n";

const cache = createIntlCache();

const initIntl = async (
  locale = getUserLocale(DEFAULT_LOCALE, AVAILABLE_LOCALES)
) => {
  let messages = {};

  try {
    messages = await loadLocaleData(locale);
  } catch (error) {
    reportError(error);
  }

  return createIntl(
    {
      locale,
      messages,
      onError: handleIntlError,
      onWarn: handleIntlWarning,
    },
    cache
  );
};

export const useIntlOutsideProvider = () => {
  const [intl, setIntl] = useState<IntlShape | null>(null);

  useEffect(() => {
    const fetchIntl = async () => {
      setIntl(await initIntl());
    };

    void fetchIntl();
  }, []);

  return intl;
};
