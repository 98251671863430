import { IS_DEV } from "@constants";
import { di } from "@di";
import { captureException, captureMessage, withScope } from "@sentry/browser";

const isDevMode = () => {
  return (
    IS_DEV ||
    (di.hasRegistration("configOverrides") &&
      di.resolve("configOverrides").isDebug)
  );
};

// TODO: Improve types
export function reportError(message: string, error?: Error): void;
export function reportError(error: Error): void;
export function reportError(message: string | Error, error?: Error): void {
  if (isDevMode()) {
    if (error) {
      console.error(message, error);
    } else if (message instanceof Error) {
      console.error(message);
    } else {
      console.warn(message);
    }

    return;
  }

  if (error) {
    captureException(error);
  } else if (message instanceof Error) {
    captureException(message);
  } else {
    captureMessage(message);
  }
}

export function reportErrorWithTag(
  error: Error,
  [tagName, tagValue]: [string, any]
) {
  if (isDevMode()) {
    console.debug(tagName, tagValue, error);
    return;
  }

  withScope((scope) => {
    scope.setTag(tagName, tagValue);
    captureException(error);
  });
}

window._reportErrorWithTag = reportErrorWithTag;

export function reportMessage(msg: string) {
  if (isDevMode()) {
    console.debug(msg);
    return;
  }

  captureMessage(msg);
}
